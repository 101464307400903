import { Fragment } from 'react';
import inRange from 'lodash/inRange';
import sum from 'lodash/sum';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { calcOptionsScores, FreqScales } from './helpers';
import { select, hasValue, valueInRange, Predicate } from './select';
import type { FeedbackComponentProps } from '.';
import { ExpandMore } from '@mui/icons-material';

export const evaluatePradas8 = calcOptionsScores([
  ['S8#Q1', [FreqScales[0], FreqScales[1]]],
  ['S8#Q2', [FreqScales[2], FreqScales[3]]],
  ['S8#Q3', FreqScales[3]],
  ['S8#Q4', FreqScales[0]],
  ['S8#Q5', [FreqScales[2], FreqScales[3]]],
  ['S8#Q6', [FreqScales[2], FreqScales[3]]],
  ['S8#Q7', ['Yes, definitely', 'Yes, partly']],
  ['S8#Q8', ['Yes, definitely', 'Yes, partly']],
  ['S8#Q9', ['Yes, definitely', 'Yes, partly']],
]);

export const Pradas8 = ({ vocab, userSurvey: state }: FeedbackComponentProps) => {
  const { childName, they, them, their, themselves, are, take, ask, find } = vocab;

  const scores = evaluatePradas8(state);
  const anxietyGroup = [scores[0], scores[1], scores[3], scores[4]];
  const understandGroup = scores.slice(6, 9);
  const manageGroup = [scores[2], scores[5]];

  const sumScore = sum(scores);
  const sumAnxietyGroup = sum(anxietyGroup);
  const sumUnderstandGroup = sum(understandGroup);
  const sumManageGroup = sum(manageGroup);

  const matchConditions = (): Predicate => {
    const applies: Record<string, boolean> = {
      '9_understand_start': sumUnderstandGroup !== 3,
    };
    return (elm) => {
      const apply = elm.key ? applies[elm.key] : undefined;
      return apply === undefined ? true : apply;
    };
  };

  const details = select(
    <AccordionDetails>
      <p key="9_body" aria-label="9_body">
        It is important for {childName} to learn to manage everyday anxiety so that it doesn’t develop into clinical
        anxiety. All young people experience some anxiety, however it can become a problem if it is severe,
        long‐lasting, or interferes with {childName}’s school or other activities.
      </p>

      <Fragment key="intro">
        <p key="9_score_low" aria-label="9_score_low">
          You can help {childName} manage {their} everyday anxiety by trying the following:
        </p>
        <p key="9_score_medium" aria-label="9_score_medium">
          You are already doing some good things to help {childName} learn how to deal with everyday anxiety.
        </p>
        <p key="9_score_high" aria-label="9_score_high">
          You are doing a great job of helping {childName} learn to deal with anxiety.
        </p>
      </Fragment>

      <Fragment key="intro_extra">
        <p key="9_score_extra_medium" aria-label="9_score_extra_medium">
          You could also try the following:
        </p>
        <p key="9_score_extra_high" aria-label="9_score_extra_high">
          To further help {childName} in this area, keep this in mind:
        </p>
      </Fragment>

      <ul key="anxiety_list" aria-label="anxiety_list">
        <li key="9_anxiety_5_a_1_a" aria-label="9_anxiety_5_a_1_a">
          Encourage {childName} to face situations that make {them} anxious (unless {their} safety or well‐being is at
          risk). Facing anxiety‐provoking situations is one of the best ways to reduce anxiety, because you find out
          that it wasn’t as bad as you had feared or that you coped with it. If {childName} avoids anxiety‐provoking
          situations, {they} {are} likely to become more anxious, not less. However, make sure that {childName} is
          capable of handling the situation before you encourage {them} to tackle it.
        </li>
        <li key="9_anxiety_2_a" aria-label="9_anxiety_2_a">
          Reward or praise {childName} if {they} {take} steps to manage {their} anxiety. If you find yourself becoming
          impatient with {childName}’s anxiety, remind yourself of how daunting it can be to face one’s fears.
        </li>
        <li key="9_anxiety_4_b" aria-label="9_anxiety_4_b">
          Try not to step in to help {childName} at the very first sign of any stress or anxiety, as the way you respond
          to {childName}’s anxiety may unintentionally increase {their} anxiety. Instead, let {them} try to manage the
          situation {themselves}, and provide help if {they} {ask} you to or if the anxiety persists.
        </li>
      </ul>

      <p key="9_understand_start" aria-label="9_understand_start">
        It would also be good for you to help {childName} understand that:
      </p>

      <ul key="understand_list" aria-label="understand_list">
        <li key="9_understand_7_d" aria-label="9_understand_7_d">
          All teenagers experience some anxiety.
        </li>
        <li key="9_understand_8_d" aria-label="9_understand_8_d">
          Anxiety is normal and helps us to prepare for situations or perform at our best.
        </li>
        <li key="9_understand_9_d" aria-label="9_understand_9_d">
          Anxiety can become a problem if it is severe, long‐lasting, or interferes with school or other activities.
        </li>
      </ul>

      <Fragment key="manage_intro">
        <p key="9_extra_low_6_c_3_d" aria-label="9_extra_low_6_c_3_d">
          It is great that you already set an example for {childName} by showing {them} how you manage your own anxiety.
          Keep it up!
        </p>
        <p key="9_extra_high_6_c_3_d" aria-label="9_extra_high_6_c_3_d">
          You are also doing an excellent job of setting an example for {childName} by showing {them} how you manage
          your own anxiety. Keep up the good work!
        </p>

        <p key="9_manage_start" aria-label="9_manage_start">
          It is also important that you set an example for {childName} by showing {them} how you can manage your own
          anxiety. To do this, you could try the following:
        </p>
      </Fragment>

      <ul key="manage_list" aria-label="manage_list">
        <li key="9_manage_3_a" aria-label="9_manage_3_a">
          When you talk to {childName} about things {they} {find} anxiety‐provoking, try to remain calm and relaxed.
        </li>
        <li key="9_manage_6_a" aria-label="9_manage_6_a">
          If you feel anxious yourself, set an example for {childName} by showing {them} how you use strategies to
          manage your anxiety. Seek professional help if you feel you need assistance to manage your own anxiety.
        </li>
      </ul>

      <p key="9_end" aria-label="9_end">
        You can learn more about supporting {childName} to manage anxiety in the module{' '}
        <strong>
          <em>Breaking the anxiety cycle</em>
        </strong>
        .
      </p>
    </AccordionDetails>,
  )
    .where(matchConditions())
    .where('intro', valueInRange(sumScore, [0, 4], [4, 8], [8, Number.MAX_VALUE]))
    .where('intro_extra', (elm, i) => {
      if (sumAnxietyGroup !== 4) {
        return i === 0 ? inRange(sumScore, 4, 8) : sumScore >= 8;
      } else {
        return false;
      }
    })
    .where(
      'anxiety_list',
      hasValue<boolean>(
        [anxietyGroup[0] === 0 || anxietyGroup[3] === 0, anxietyGroup[1] === 0, anxietyGroup[2] === 0],
        true,
      ),
    )
    //    .where('9_understand_start', () => sumUnderstandGroup !== 3)
    .where('understand_list', hasValue<Number>(understandGroup, 0))
    .where(
      'manage_intro',
      hasValue<boolean>(
        [
          sumManageGroup === 2 && inRange(sumScore, 0, 4),
          sumManageGroup === 2 && inRange(sumScore, 4, Number.MAX_VALUE),
          sumManageGroup < 2,
        ],
        true,
      ),
    )
    .where('manage_list', hasValue<Number>(manageGroup, 0))
    .element();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pradas-1" id="pradas-1">
        <Typography key="2_heading" color="primary" gutterBottom>
          Coping with Anxiety
        </Typography>
      </AccordionSummary>
      {details}
    </Accordion>
  );
};
