import { Fragment } from 'react';
import sum from 'lodash/sum';
import { Typography, AccordionDetails, Accordion, AccordionSummary } from '@mui/material';
import { calcOptionsScores, FreqScales } from './helpers';
import { select, hasValue, valueInRange } from './select';

import type { FeedbackComponentProps } from '.';
import { ExpandMore } from '@mui/icons-material';

export const evaluatePradas2 = calcOptionsScores([
  ['S2#Q1', FreqScales[3]],
  ['S2#Q2', [FreqScales[2], FreqScales[3]]],
  ['S2#Q3', FreqScales[0]],
  ['S2#Q4', [FreqScales[0], FreqScales[1]]],
  ['S2#Q5', FreqScales[3]],
  ['S2#Q6', [FreqScales[0], FreqScales[1]]],
  ['S2#Q7', [FreqScales[2], FreqScales[3]]],
  ['S2#Q8', FreqScales[3]],
]);

export const Pradas2 = ({ vocab, userSurvey: state }: FeedbackComponentProps) => {
  const scores = evaluatePradas2(state);
  const { childName, them, they, are, their } = vocab;

  const lifeScores = [scores[0], scores[1], scores[3], scores[4]];
  const independenceScores = [scores[2], scores[5], scores[6], scores[7]];
  const startBlockControls: Record<string, boolean> = {
    '3_life_start': sum(lifeScores) < 4,
    '3_independence_start': sum(independenceScores) < 4,
  };

  const details = select(
    <AccordionDetails>
      <p key="3_body" aria-label="3_body">
        It is important to find a balance between being involved in {childName}’s life and giving {them} age‐appropriate
        independence.
      </p>

      <Fragment key="intro">
        <p key="3_white_low" aria-label="3_white_low">
          Teenagers benefit most when their parents continue to show interest in their lives without being intrusive,
          and respect their need for growing independence.
        </p>
        <p key="3_white_high" aria-label="3_white_high">
          Teenagers benefit most when their parents continue to show interest in their lives without being intrusive,
          and respect their need for growing independence.
        </p>
        <p key="3_white_best" aria-label="3_white_best">
          You seem to have found a good balance, well done!
        </p>
      </Fragment>

      <Typography key="3_life_start" aria-label="3_life_start" variant="subtitle1">
        <strong>You can be more involved in {childName}’s life by:</strong>
      </Typography>
      <ul key="life_list" aria-label="life_list">
        <li key="3_life_1_a" aria-label="3_life_1_a">
          Eating dinner together as a family more often.
        </li>
        <li key="3_life_2_a" aria-label="3_life_2_a">
          Doing enjoyable one‐on‐one activities with {childName} regularly. This could be anything that you both enjoy ‐
          be creative!
        </li>
        <li key="3_life_4_c" aria-label="3_life_4_c">
          Showing interest in what {childName} is doing at school. For example, you could ask who {they} spent time with
          at lunch time, or what was the easiest/hardest thing {they} had to do at school that day.
        </li>
        <li key="3_life_5_a" aria-label="3_life_5_a">
          If {childName} is going out without you, have a casual chat with {them} about what {they} will be doing, where{' '}
          {they} will be, and who {they} will be with.
        </li>
      </ul>

      <Typography key="3_independence_start" aria-label="3_independence_start" variant="subtitle1">
        You can further support {childName}’s growing independence by:
      </Typography>
      <ul key="independence_list" aria-label="independence_list">
        <li key="3_independence_3_b" aria-label="3_independence_3_b">
          Encouraging {childName} to try out extra‐curricular activities (e.g. sports, music, or anything else {they}{' '}
          {are} interested in).
        </li>
        <li key="3_independence_6_c" aria-label="3_independence_6_c">
          Allowing {childName} to become more independent of you over time. Evaluate whether you are taking over things
          too much. For example, you can ask yourself, “Did I really need to step in?” and “What would have been the
          worst thing to happen if I didn’t step in?”
        </li>
        <li key="3_independence_7_a" aria-label="3_independence_7_a">
          Encouraging {childName} to try out a variety of activities, to find out what {they} {are} interested in and
          what {they} {are} good at. This helps to build {their} self‐confidence.
        </li>
        <li key="3_independence_8_a" aria-label="3_independence_8_a">
          Gradually increasing {childName}’s responsibilities and independence over time to allow {them} to mature.
        </li>
      </ul>

      <p key="3_end" aria-label="3_end">
        You can learn more about the balance between being involved in {childName}’s life and supporting {their}{' '}
        developing autonomy in the module{' '}
        <strong>
          <em>Nurture roots and inspire wings</em>
        </strong>
        .
      </p>
    </AccordionDetails>,
  )
    .where((element) => {
      const show = startBlockControls[element.key!];
      return show === undefined ? true : show;
    })
    .where('intro', valueInRange(sum(scores), [0, 4], [4, 7], [7, Number.MAX_VALUE]))
    .where('life_list', hasValue(lifeScores, 0))
    .where('independence_list', hasValue(independenceScores, 0))
    .element();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pradas-1" id="pradas-1">
        <Typography key="2_heading" color="primary" gutterBottom>
          Your Involvement in {childName}’s Life
        </Typography>
      </AccordionSummary>
      {details}
    </Accordion>
  );
};
