import Typography from '@mui/material/Typography';

export function FollowUpFeedbackAfterCASP() {
  return (
    <section>
      <Typography paragraph>
        Thank you for completing the post-program questions about your child. Please select 'Next' to complete the next
        section about your program experience.
      </Typography>
    </section>
  );
}
