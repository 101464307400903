import { Fragment } from 'react';
import sum from 'lodash/sum';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { calcOptionsScores, FreqScales } from './helpers';
import { select, hasValue, valueInRange } from './select';
import type { FeedbackComponentProps } from '.';
import { ExpandMore } from '@mui/icons-material';

export const evaluatePradas5 = calcOptionsScores([
  ['S5#Q1', [FreqScales[2], FreqScales[3]]],
  ['S5#Q2', FreqScales[0]],
  ['S5#Q3', [FreqScales[2], FreqScales[3]]],
  ['S5#Q4', FreqScales[0]],
  ['S5#Q5', FreqScales[0]],
  ['S5#Q6', [FreqScales[3], FreqScales[4]]],
  ['S5#Q7', [FreqScales[0], FreqScales[4]]],
  ['S5#Q8', FreqScales[3]],
]);

export const Pradas5 = ({ vocab, userSurvey: state }: FeedbackComponentProps) => {
  const { childName, them, they, their, themselves, shut } = vocab;
  const scores = evaluatePradas5(state);
  const details = select(
    <AccordionDetails>
      <p key="6_body" aria-label="6_body">
        Having a supportive and safe home environment helps to reduce {childName}’s risk of depression and clinical
        anxiety. This includes minimising conflict at home, and setting an example for {childName} by using positive
        approaches to handling conflict.
      </p>

      <Fragment key="intro">
        <p key="6_white_low" aria-label="6_white_low">
          Being a part of a family where there is frequent or unresolved conflict increases {childName}’s risk of
          depression and clinical anxiety. While you may not be able to avoid conflict altogether, there are a number of
          things that you can do to make the home environment supportive and safe for {childName}, including:
        </p>
        <p key="6_white_medium" aria-label="6_white_medium">
          Being a part of a family where there is frequent or unresolved conflict increases {childName}’s risk of
          depression and clinical anxiety. You already manage conflict at home in a number of ways. There are some other
          things that you could do, including:
        </p>
        <div key="6_white_high" aria-label="6_white_high">
          <p>You are doing a great job of managing conflict at home. Well done!</p>
          <p>To further improve in this area, remember this:</p>
        </div>
        <p key="6_white_best" aria-label="6_white_best">
          You are doing a great job of managing conflict at home. Well done!
        </p>
      </Fragment>

      <ul key="white_list" aria-label="white-list">
        <li key="6_white_1_b" aria-label="6_white_1_b">
          If you have an argument or conflict with {childName}, try to problem‐solve the issue together.
        </li>
        <li key="6_white_2_c" aria-label="6_white_2_c">
          If you are experiencing conflict with {childName}, continue to show {them} affection and keep having normal
          everyday conversations with {them}. Tell {them} that you are still there for {them}, even if {they} {shut} you
          out.
        </li>
        <li key="6_white_3_a" aria-label="6_white_3_a">
          Try to minimise conflict with {childName} where possible. You can do this by considering which issues are
          minor and can be ignored, and which are important for {childName}’s safety and wellbeing and therefore should
          be addressed.
        </li>
        <li key="6_white_4_b" aria-label="6_white_4_b">
          If you are not happy with {childName}’s behaviour, don’t criticise {them} in a personal way (e.g. “You are so
          lazy and spoilt”). Instead, talk to {them} specifically about {their} actions (e.g. “You put in the effort for
          sport, but what about your studies? Can we find a way to balance your time better?”). Encourage {them} to
          think of {their} specific actions as good or bad, rather than seeing {themselves} as a good or bad person.
        </li>
        <li key="6_white_5_d" aria-label="6_white_5_d">
          If you lose your temper with {childName}, acknowledge it and apologise to {them} rather than brushing it off
          and forgetting about it.
        </li>
        <li key="6_white_6_b" aria-label="6_white_6_b">
          Try not to argue with your partner if {childName} can hear. Frequent and intense conflict between parents
          increases a teenager’s risk of depression and clinical anxiety.
        </li>
        <li key="6_white_7_d" aria-label="6_white_7_d">
          If you argue with your partner, do not ask {childName} to choose sides.
        </li>
        <li key="6_white_8_c" aria-label="6_white_8_c">
          If you feel angry with others at home, try to take some time to calm down before trying to resolve the issue.
        </li>
      </ul>

      <p key="6_end" aria-label="6-end">
        Check out the module{' '}
        <strong>
          <em>Calm versus conflict</em>
        </strong>{' '}
        for more strategies to help keep your home environment supportive for {childName}.
      </p>
    </AccordionDetails>,
  )
    .where('intro', valueInRange(sum(scores), [0, 4], [4, 7], 7, [8, Number.MAX_VALUE]))
    .where('white_list', hasValue(scores, 0))
    .element();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pradas-1" id="pradas-1">
        <Typography key="2_heading" color="primary" gutterBottom>
          Your Home Environment
        </Typography>
      </AccordionSummary>
      {details}
    </Accordion>
  );
};
