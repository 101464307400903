import { useVocabulary } from 'app/helpers';
import { Typography } from '@mui/material';
import { Confidence } from './Confidence';
import { Pradas } from './Pradas';
import { Parcads } from './Parcads';
import { BaselineFeedbackAfterK6 } from './BaselineFeedbackAfterK6';
import { FollowUpFeedbackAfterK6 } from './FollowUpFeedbackAfterK6';
import { FollowUpFeedbackAfterCASP } from './FollowUpFeedbackAfterCASP';
import { FollowUpFeedbackAfterCSQ8 } from './FollowUpFeedbackAfterCSQ8';
import { K6 } from './K6';
import { Rcads } from './Rcads';
import { Article } from './Article';

import type { ComponentType } from 'react';
import type { state } from 'app';
import type { FeedbackComponentProps } from '.';

export const feedbacks: Record<string, ComponentType<FeedbackComponentProps>> = {
  's1-confidence': Confidence,
  's2-pradas': Pradas,
  's3-k6': K6,
  's4-rcads': Rcads,
  'f1-evaluation': Confidence,
  'f2-pradas': Pradas,
  'f3-k6': K6,
  'f4-rcads': Rcads,
  'pka-parcads': BaselineFeedbackAfterK6,
  'pka-optional': Parcads,
  'pka-parcads-followup': FollowUpFeedbackAfterK6,
  'pka-optional-followup-1': FollowUpFeedbackAfterCASP,
  'pka-optional-followup-2': FollowUpFeedbackAfterCSQ8,
};

export type FeedbackProps = {
  surveyId: string;
  user: state.User;
  userSurvey: state.Survey;
};

export function Feedback(props: FeedbackProps) {
  const { user, surveyId, userSurvey } = props;
  const FeedbackComp = feedbacks[surveyId];
  const vocab = useVocabulary(user);

  return (
    <Article>
      {FeedbackComp ? (
        <FeedbackComp user={user} userSurvey={userSurvey} vocab={vocab} />
      ) : (
        <Typography color="error">No feedback for {surveyId}</Typography>
      )}
    </Article>
  );
}
