import { Box, Typography } from '@mui/material';
import { Link } from 'components';
import { FeedbackComponentProps } from '.';
import { Parcads1 } from './Parcads1';
import { Parcads2 } from './Parcads2';
import { Parcads3 } from './Parcads3';
import { Parcads4 } from './Parcads4';
import { Parcads5 } from './Parcads5';
import { Parcads6 } from './Parcads6';
import { Parcads7 } from './Parcads7';
import { Parcads8 } from './Parcads8';
import { Parcads9 } from './Parcads9';
import { Parcads10 } from './Parcads10';
import { useAppSelector} from "app/store"

export function Parcads(props: FeedbackComponentProps) {
  const { vocab, userSurvey } = props;
  const { childName } = vocab;
 const parcadsAnswer = useAppSelector((state) => state.profile.data)?.surveys['pka-parcads'];
  return (
    <section>
      <Typography paragraph>You have now completed all of your pre-program questions.</Typography>

      <Typography variant="h5" color="primary" gutterBottom>
        Your Personalised Parenting Tips
      </Typography>

      <Typography paragraph>
        Thank you for completing your pre-program surveys. We can now provide you with feedback about your role in
        building {childName}’s resilience, along with some practical strategies that you can use.
      </Typography>

      <Typography variant="h6" gutterBottom>
        What next?
      </Typography>

      <Typography paragraph>
        This feedback report contains strategies that may be useful for you and {childName}. This may seem like a lot of
        information, so if you're not sure where to start, perhaps just have a quick read over it first. You don't need
        to try all of the recommended strategies at once. Remember, change can take time and patience. In the coming
        weeks, we will provide you with any further support you might need to apply the suggested strategies, through
        interactive online modules that are specially recommended for you. If you feel that there are things you could
        have done differently as a parent, try not to be too hard on yourself. You've already taken a positive step by
        completing the survey.
      </Typography>

      <Box sx={{ my: 2 }}>
        <Parcads1 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
        <Parcads2 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
        <Parcads3 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
        <Parcads4 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
        <Parcads5 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
        <Parcads6 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
        <Parcads7 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
        <Parcads8 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
        <Parcads9 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
        <Parcads10 {...{ vocab, userSurvey: { ...parcadsAnswer, ...userSurvey } }} />
      </Box>

      <Typography variant="h5" color="primary" gutterBottom>
        Don’t Blame Yourself
      </Typography>

      <Typography paragraph>
        There are lots of different reasons why children develop mental health problems. If {childName} were to develop
        depression or an anxiety disorder, despite your best efforts, you should not view it as a failure on your part.
        Remember that it is important to take care of yourself, and seek additional support and/or professional help if
        you think you need it. <Link href="/resources">Click here</Link> for useful links
      </Typography>

      <Typography paragraph>
        Please select 'Select my modules' to choose which modules you would like to complete as part of your program.
        You can then start your first module right away!
      </Typography>
    </section>
  );
}
