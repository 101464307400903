import { Fragment } from 'react';
import sum from 'lodash/sum';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { calcOptionsScores, FreqScales } from './helpers';
import { select, hasValue, Predicate } from './select';
import type { FeedbackComponentProps } from '.';
import { state } from 'app';
import { ExpandMore } from '@mui/icons-material';

export const evaluatePradas4 = calcOptionsScores([
  ['S4#Q1', 'Yes'],
  ['S4#Q2', 'Yes'],
  ['S4#Q3', 'Yes'],
  ['S4#Q4', 'Yes'],
  ['S4#Q5', 'Yes, to adapt to my teenager’s maturity and responsibility.'],
  ['S4#Q6', FreqScales[0]],
  ['S4#Q7', FreqScales[3]],
  ['S4#Q8', FreqScales[0]],
  ['S4#Q9', [FreqScales[2], FreqScales[3]]],
]);

export const Pradas4 = ({ vocab, userSurvey: state }: FeedbackComponentProps) => {
  const { childName, their, they, are, dont, them } = vocab;
  const scores = evaluatePradas4(state);
  const details = select(
    <AccordionDetails>
      <Fragment key="list-rule">
        <p key="5_rules_2_b_1_b" aria-label="5_rules_2_b_1_b">
          Establishing clear rules and consequences for {childName}’s behaviour is important in reducing {their} risk of
          depression and clinical anxiety. If clear rules are established from an early age, {childName} is more likely
          to accept the rules than if they are established for the first time when {they} {are} older. You could help{' '}
          {childName} by establishing specific, defined rules for {their} behaviour, and consequences for when {they}{' '}
          {dont} follow the rules.
        </p>
        <p key="5_rules_2_b_1_a" aria-label="5_rules_2_b_1_a">
          It is great that you have set specific, defined consequences for {childName}’s behaviour. It is also important
          to establish clear rules that are linked to these consequences. Having clear rules <em>and</em> consequences
          helps to reduce {their} risk of depression and clinical anxiety.
        </p>
        <p key="5_rules_2_a_1_b" aria-label="5_rules_2_a_1_b">
          It is great that you have set specific, defined rules for {childName}’s behaviour. It is also important to set
          clear consequences for when {they} {dont} follow these rules. Having clear rules <em>and</em> consequences
          helps to reduce {their} risk of depression and clinical anxiety.
        </p>
        <p key="5_rules_2_a_1_a" aria-label="5_rules_2_a_1_a">
          It is great that you have set specific, defined rules and consequences for {childName}’s behaviour, as this
          helps to reduce {their} risk of depression and clinical anxiety.
        </p>
      </Fragment>

      <Fragment key="positive-family-rules-intro">
        <p key="5_also_2_b_1_a" aria-label="5_also_2_b_1_a">
          It is also great that:
        </p>
        <p key="5_also_2_b_1_b" aria-label="5_also_2_b_1_b">
          It is great that:
        </p>
      </Fragment>

      <ul key="green_list" aria-label="green_list">
        {/* S4Q3 === 'Yes' */}
        <li key="5_green_3_a" aria-label="5_green_3_a">
          {childName} is involved in developing the rules for {their} behaviour.
        </li>

        {/* S4Q4 === 'Yes' */}
        <li key="5_green_4_a" aria-label="5_green_4_a">
          You have talked with {childName} to help {them} understand the reasons behind the family rules (e.g. to ensure{' '}
          {their} safety).
        </li>

        {/* S4Q5 === 'Yes, to adapt to my teenager’s maturity and responsibility.' */}
        <li key="5_green_5_b" aria-label="5_green_5_b">
          You adapt the family rules to reflect {childName}’s maturity and responsibility.
        </li>

        {/* S4Q6 === 'Never' */}
        <li key="5_green_6_a" aria-label="5_green_6_a">
          You uphold the family rules and consequences, even if {childName} isn’t happy about it.
        </li>

        {/* S4Q7 === 'Often' */}
        <li key="5_green_7_d" aria-label="5_green_7_d">
          When you enforce consequences, you talk with {childName} about why {their} behaviour was not acceptable.
        </li>

        {/* S4Q8 === 'Never' */}
        <li key="5_green_8_a" aria-label="5_green_8_a">
          You don’t use consequences that make {childName} feel embarrassed.
        </li>

        {/* S4Q9 === 'Sometimes' || 'Often' */}
        <li key="5_green_9_c" aria-label="5_green_9_c">
          You notice when {childName} behaves well and reward {them} with positive consequences (e.g. praise or
          privileges)
        </li>
      </ul>

      <Fragment key="family-rules-to-improve-intro">
        <p key="5_white_start" aria-label="5_white_start">
          You could improve the setting of family rules by doing the following:
        </p>
      </Fragment>

      <ul key="white_list" aria-label="white_list">
        {/* S4Q3 !== 'Yes' */}
        <li key="5_white_3_c" aria-label="5_white_3_c">
          Involving {childName} in the development of rules for {their} behaviour.
        </li>

        {/* S4Q4 !== 'Yes' */}
        <li key="5_white_4_b" aria-label="5_white_4_b">
          Talking with {childName} to help {them} understand the reasons behind the family rules (e.g. to ensure {their}{' '}
          safety).
        </li>

        {/* S4Q5 === 'Yes, all the time.' */}
        <li key="5_white_5_a" aria-label="5_white_5_a">
          Keeping family rules stable and consistent, and only changing them to reflect {childName}’s maturity and
          responsibility.
        </li>

        {/* S4Q5 === 'No, family rules apply for as long as my teenager is under my care.' */}
        <li key="5_white_5_c" aria-label="5_white_5_c">
          Reviewing your family rules at regular intervals (e.g. once a year) and adapting them to reflect {childName}
          ’s maturity and responsibility.
        </li>

        {/* S4Q6 !== 'Never' */}
        <li key="5_white_6_b" aria-label="5_white_6_b">
          Upholding the family rules and consequences, even if {childName} isn’t happy about it.
        </li>

        {/* S4Q7 !== 'Offen' */}
        <li key="5_white_7_a" aria-label="5_white_7_a">
          When you enforce consequences, explain to {childName} why {their} behaviour wasn’t acceptable.
        </li>

        {/* S4Q8 !== 'Never' */}
        <li key="5_white_8_b" aria-label="5_white_8_b">
          Not using consequences that make {childName} feel embarrassed.
        </li>

        {/* S4Q9 !== 'Sometimes' || 'Often' */}
        <li key="5_white_9_a" aria-label="5_white_9_a">
          Noticing when {childName} behaves well, and rewarding {them} with positive consequences (e.g. praise or
          privileges).
        </li>
      </ul>

      <Fragment key="recap">
        <p key="5_white_end" aria-label="5_white_end">
          {childName} is more likely to follow the family rules if you do these things.
        </p>
      </Fragment>

      <p key="5_end">
        For more information about setting family rules, see the module{' '}
        <strong>
          <em>Raising good kids into great adults</em>
        </strong>
        .
      </p>
    </AccordionDetails>,
  )
    .where('list-rule', listRulesIndex(scores))
    .where('positive-family-rules-intro', familyRulesIntroKey(scores))
    .where('green_list', hasValue(scores.slice(2, 9), 1))
    .where('family-rules-to-improve-intro', (elm) => sum(scores.slice(2, 9)) !== 7)
    .where('white_list', whiteListOptions(scores, state))
    .where('recap', (elm) => sum(scores.slice(2, 9)) < 6)
    .element();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pradas-1" id="pradas-1">
        <Typography key="2_heading" color="primary" gutterBottom>
          Your Family Rules
        </Typography>
      </AccordionSummary>
      {details}
    </Accordion>
  );
};

/**
 * Filter for white list, it is a reverse of the expect answer. Also replace S4Q5 reverse with 2 options.
 */
const whiteListOptions = (scores: number[], state: state.Survey) => {
  let options = scores.slice(2, 9);
  const q5a = state['S4#Q5'] == 'Yes, all the time.' ? 0 : 1;
  const q5c = state['S4#Q5'] == 'No, family rules apply for as long as my teenager is under my care.' ? 0 : 1;
  options.splice(2, 1, q5a, q5c);
  return hasValue(options, 0);
};

const listRulesIndex = (scores: number[]): Predicate => {
  const q2q1 = scores[1] * 2 + scores[0];
  return (elm, i) => i === q2q1;
};

const familyRulesIntroKey = (scores: number[]): Predicate => {
  const sum2to9 = sum(scores.slice(2, 9));
  const sum0to2 = sum(scores.slice(0, 2));
  const key = sum2to9 !== 0 && (sum0to2 !== 0 ? '5_also_2_b_1_a' : '5_also_2_b_1_b');
  return (elm, i) => {
    return key && key === elm.key;
  };
};
