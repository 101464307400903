import Typography from '@mui/material/Typography';

export function FollowUpFeedbackAfterK6() {
  return (
    <section>
      <Typography paragraph>
        Thank you for completing the post-program questions about your parenting and wellbeing. Please select 'Next' to
        complete the next section about your child.
      </Typography>
    </section>
  );
}
