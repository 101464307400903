import { Fragment } from 'react';
import sum from 'lodash/sum';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { calcOptionsScores, FreqScales, LikelyScales } from './helpers';
import { select, hasValue, valueInRange, Predicate } from './select';
import type { FeedbackComponentProps } from '.';
import { ExtLink } from 'components/Link';
import { ExpandMore } from '@mui/icons-material';

export const evaluatePradas6 = calcOptionsScores([
  ['S6#Q1', FreqScales[3]],
  ['S6#Q2', [FreqScales[0], FreqScales[1]]],
  ['S6#Q3', FreqScales[3]],
  ['S6#Q4', FreqScales[3]],
  ['S6#Q5', FreqScales[3]],
  ['S6#Q6', FreqScales[0]],
  ['S6#Q7', [FreqScales[2], FreqScales[3]]],
  ['S6#Q8', FreqScales[0]],
  ['S6#Q9', [LikelyScales[2], LikelyScales[3]]],
  ['S6#Q10', [LikelyScales[0], LikelyScales[1]]],
  ['S6#Q11', [LikelyScales[0], LikelyScales[1]]],
  ['S6#Q12', [LikelyScales[2], LikelyScales[3]]],
]);

export const Pradas6 = ({ vocab, userSurvey: state }: FeedbackComponentProps) => {
  const { childName, their, they, them, are } = vocab;
  const scores = evaluatePradas6(state);

  const healthScores = [scores[0], scores[1], scores[2], scores[6], scores[7]];
  const sleepScores: boolean[] = [
    scores[3] === 0,
    scores[4] === 0,
    scores[3] === 1 && scores[5] === 0,
    scores[3] === 0 && scores[5] === 0,
  ];

  const matchConditions = (): Predicate => {
    const drugBest = sum(scores.slice(8, 12)) === 4;
    const applyExtraHigh = sum(scores.slice(0, 8)) === 7 && sum(healthScores) < 5;
    const shouldShowSleep = sleepScores.indexOf(true) >= 0;
    const applies: Record<string, boolean> = {
      '7_drugs_best': drugBest,
      '7_drugs_low': !drugBest,
      '7_score_extra_high': applyExtraHigh,
      '7_sleep_start': shouldShowSleep,
      sleep_list: shouldShowSleep,
      '7_sleep_end': shouldShowSleep,
    };
    return (elm, i) => {
      const apply = elm.key ? applies[elm.key] : undefined;
      return apply === undefined ? true : apply;
    };
  };

  const details = select(
    <AccordionDetails>
      <p key="7_body" aria-label="7_body">
        Having a healthy diet and lifestyle is important for {childName}’s mental health and wellbeing. This includes
        eating a balanced diet, exercising regularly, getting enough sleep, and not using alcohol or other drugs.
      </p>

      <Fragment key="intro">
        <p key="7_score_low" aria-label="7_score_low">
          Here are some strategies you could use to help {childName} develop good health habits:
        </p>
        <p key="7_score_medium" aria-label="7_score_medium">
          You are already helping {childName} to develop some good health habits, well done! To improve {their} health
          habits, here are some strategies you could use:
        </p>
        <p key="7_score_high" aria-label="7_score_high">
          You are doing a great job of encouraging {childName} to develop good health habits – keep up the great work!
        </p>
      </Fragment>

      <p key="7_score_extra_high" aria-label="7_score_extra_high">
        It is also important to remember this:
      </p>

      <ul key="health_list">
        <li key="7_health_1_c" aria-label="7_health_1_c">
          Make sure that {childName} eats a healthy, balanced diet, including a wide variety of nutritious foods. For
          information on the Australian dietary guidelines, click{' '}
          <ExtLink href="https://www.eatforhealth.gov.au/sites/default/files/content/The%20Guidelines/n55f_children_brochure.pdf">
            here
          </ExtLink>
          .
        </li>
        <li key="7_health_2_d" aria-label="7_health_2_d">
          Limit the amount of unhealthy foods and drinks (e.g. chips, biscuits, chocolates, soft drinks) in the house.
        </li>
        <li key="7_health_3_c" aria-label="7_health_3_c">
          Set an example for {childName} by having good health habits (i.e. healthy diet, regular exercise and
          responsible use of alcohol) yourself.
        </li>
        <li key="7_health_7_a" aria-label="7_health_7_a">
          Encourage {childName} to get regular physical exercise, at least most days. If {they} {are} not interested in
          sports, encourage {them} to find other activities, such as going for a walk, riding a bike, or walking to
          school. For more information on the Australian physical activity guidelines for young people, click{' '}
          <ExtLink href="https://www.health.gov.au/sites/default/files/documents/2021/05/24-hour-movement-guidelines-children-and-young-people-5-to-17-years-fact-sheet.pdf">
            here
          </ExtLink>
          .
        </li>
        <li key="7_health_8_d" aria-label="7_health_8_d">
          Do not allow {childName} to have any alcohol. Research shows that drug and alcohol use is harmful for
          teenagers’ physical health and brain development. The National Health and Medical Research Council (NHMRC)
          recommends that teenagers under 15 years do not consume any alcohol, and that alcohol consumption is delayed
          for as long as possible in older teenagers. To find out more about young people and alcohol use, click{' '}
          <ExtLink href="https://www.parentingstrategies.net">here</ExtLink>.
        </li>
      </ul>

      <div key="7_sleep_start" aria-label="7_sleep_start">
        <p>
          <strong>{childName}’s sleep</strong>
        </p>
        <p>
          Getting enough sleep each night can reduce {childName}’s risk of developing depression and clinical anxiety.
          You can improve {childName}’s sleep by encouraging {them} to have healthy sleep habits, including:
        </p>
      </div>

      <ul key="sleep_list">
        <li key="7_sleep_4_a" aria-label="7_sleep_4_a">
          Going to bed and getting up at roughly the same time each day (no more than a two‐hour difference between
          weekdays and weekends). This helps to regulate {childName}’s internal ‘body clock,’ which will help {them} get
          a better night’s sleep.
        </li>
        <li key="7_sleep_5_a" aria-label="7_sleep_5_a">
          Not watching TV or using electronic devices in bed before going to sleep. The bright light from electronic
          devices can make it harder for {childName} to get to sleep. Instead, encourage {them} to wind down with
          relaxing activities (e.g. reading, having a shower or bath, or listening to calming music).
        </li>
        <li key="7_sleep_6_b_4_d" aria-label="7_sleep_6_b_4_d">
          Not sleeping‐in on weekends. Instead, encourage {childName} to get up at roughly the same time each day (no
          more than two hours difference between weekdays and weekends). If you think {childName} needs more sleep,
          encourage {them} to go to bed earlier each night. Sleeping in too late on weekends can make it harder for{' '}
          {childName} to wake up on weekday mornings.
        </li>
        <li key="7_sleep_6_b_4_a" aria-label="7_sleep_6_b_4_a">
          Not sleeping‐in on weekends. If you think {childName} needs more sleep, encourage {them} to go to bed earlier
          each night. Sleeping in too late on weekends can make it harder for {childName} to wake up on weekday
          mornings.
        </li>
      </ul>
      <p key="7_sleep_end" aria-label="7_sleep_end">
        To read more about teenage sleep, click{' '}
        <ExtLink href="http://raisingchildren.net.au/articles/sleep_early_teens.html/context/1069">here</ExtLink>.
      </p>

      <div key="7_drugs_start" aria-label="7_drugs_start">
        <p>
          <strong>Alcohol or drug use</strong>
        </p>
        <p>It is not uncommon for teenagers to experiment with alcohol or other drugs.</p>
      </div>

      <div key="7_drugs_best" aria-label="7_drugs_best">
        <p>
          You already seem to have a good idea of what to do if you find out that {childName} is using alcohol or other
          drugs.
        </p>
        <p>
          For guidelines on parenting strategies to prevent alcohol misuse in teenagers, click{' '}
          <ExtLink href="https://www.parentingstrategies.net">here</ExtLink>.
        </p>
      </div>

      <div key="7_drugs_low" aria-label="7_drugs_low">
        <p>
          It is important that you know how to respond to this situation if it does occur. We suggest you handle it by:
        </p>
        <ul>
          <li>
            Calmly talking with {childName} about why {they} {are} using the alcohol or drugs.
          </li>
          <li>
            Being careful to express your disappointment with {their} behaviour without being overly critical of {them}{' '}
            as a person.
          </li>
          <li>Enforcing a reasonable consequence, rather than punishing {them} in anger.</li>
          <li>Seeking professional help if you think it is needed.</li>
        </ul>
        <p>
          For guidelines on parenting strategies to prevent alcohol misuse in teenagers that are supported by research
          evidence and endorsed by national experts, click{' '}
          <ExtLink href="https://www.parentingstrategies.net">here</ExtLink>.
        </p>
      </div>

      <p key="7_end" aria-label="7_end">
        To find out more about supporting {childName}’s health habits, see the module{' '}
        <strong>
          <em>Good health habits for good mental health</em>
        </strong>
        .
      </p>
    </AccordionDetails>,
  )
    .where(matchConditions())
    .where('intro', valueInRange(sum(scores.slice(0, 8)), [0, 4], [4, 7], [7, Number.MAX_VALUE]))
    .where('health_list', hasValue<number>(healthScores, 0))
    .where('sleep_list', hasValue<boolean>(sleepScores, true))
    .element();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pradas-1" id="pradas-1">
        <Typography key="2_heading" color="primary" gutterBottom>
          Health Habits
        </Typography>
      </AccordionSummary>
      {details}
    </Accordion>
  );
};
