import { Box, Typography } from '@mui/material';
import { markCompleted, updateSurvey, useAppDispatch } from 'app/store';
import { QuestionStepper } from './questions';
import { surveyProgress } from './helpers';

import type { state, data } from 'app';

export type SurveyQuestionsProps = {
  activeStep: number;
  survey: data.Survey;
  userSurvey: state.Survey;
  isLast: boolean;
};

export function SurveyQuestions(props: SurveyQuestionsProps) {
  const { activeStep, survey, userSurvey, isLast } = props;
  const dispatch = useAppDispatch();

  const handleChange = (data: Record<string, any>) => {
    const [_totalRequired, _completedRequired, _totalOptional, _completedOptional] = surveyProgress(
      survey.sections.data,
      data as state.Survey,
    );
    dispatch(
      updateSurvey({
        id: survey.key,
        data: { ...data, _totalRequired, _completedRequired, _totalOptional, _completedOptional },
      }),
    );
  };

  const setStep = (step: number) => {
    dispatch(updateSurvey({ id: survey.key, data: { _step: step } }));
    // If this was the last section of the last survey, mark
    // the appropriate scope completed
    if (isLast && step === survey.sections.data.length) {
      dispatch(markCompleted({ scope: survey.scope === 'baseline' ? 'survey' : 'followup' }));
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {survey.title}
      </Typography>
      <QuestionStepper
        step={activeStep}
        setStep={setStep}
        data={userSurvey}
        onChange={handleChange}
        sections={survey.sections.data}
        finishLabel={isLast ? 'Finish' : 'Submit'}
      />
    </Box>
  );
}
