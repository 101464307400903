import Typography from '@mui/material/Typography';

export function FollowUpFeedbackAfterCSQ8() {
  return (
    <section>
      <Typography paragraph>
        Thank you for taking the time to complete your post-program questions! Your time and feedback is extremely
        valuable and we would like to congratulate you on supporting your child's mental health!
      </Typography>
      <Typography paragraph>
        As a token of our appreciation, please look out for your study thank you e-voucher, which will be emailed to you
        in the coming days!
      </Typography>
    </section>
  );
}
