import Typography from '@mui/material/Typography';

export function BaselineFeedbackAfterK6() {
  return (
    <section>
      <Typography paragraph>
        Thank you for completing the pre-program questions about your wellbeing and parenting. Please select 'Next' to
        move to the 'Your Child and Starting Your Program' questions and feedback.
      </Typography>
    </section>
  );
}
