import { Fragment } from 'react';
import sum from 'lodash/sum';
import { Typography, AccordionSummary, Accordion, AccordionDetails } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { calcOptionsScores, FreqScales } from './helpers';
import { select, hasValue, valueInRange } from './select';
import type { FeedbackComponentProps } from '.';

export const evaluatePradas1 = calcOptionsScores([
  ['S1#Q1', FreqScales[3]],
  ['S1#Q2', [FreqScales[2], FreqScales[3]]],
  ['S1#Q3', [FreqScales[2], FreqScales[3]]],
  ['S1#Q4', FreqScales[0]],
  ['S1#Q5', FreqScales[3]],
  ['S1#Q6', FreqScales[0]],
  ['S1#Q7', FreqScales[3]],
  ['S1#Q8', FreqScales[0]],
]);

export const Pradas1 = ({ vocab, userSurvey: state }: FeedbackComponentProps) => {
  const scores = evaluatePradas1(state);
  const { childName, them, their, they, appear, are, seem, have, need } = vocab;
  const details = select(
    <AccordionDetails>
      <p key="2_body" aria-label="2_body">
        Your care and support for {childName} helps to reduce {their} risk of depression and clinical anxiety.
      </p>

      <Fragment key="intro">
        <p key="2_white_low" aria-label="2_white_low">
          {childName} would benefit from having a closer relationship with you. There are some things you could do to
          improve your relationship, such as:
        </p>
        <p key="2_white_medium" aria-label="2_white_medium">
          You are doing lots of things to build a good relationship with {childName}. There are some other things you
          may also like to consider doing, such as:
        </p>
        <p key="2_white_high" aria-label="2_white_high">
          You are doing a great job of building a good relationship with {childName} by being involved in {their} life,
          encouraging communication between the two of you, and supporting {them}. Keep up the good work! You may also
          like to consider:
        </p>
        <p key="2_white_best" aria-label="2_white_best">
          You are doing a great job of building a good relationship with {childName} by being involved in {their} life,
          encouraging communication between the two of you, and supporting {them}. Keep up the good work!
        </p>
      </Fragment>

      <ul key="white_list" aria-label="white_list">
        <li key="2_white_1_a" aria-label="2_white_1_a">
          Verbally expressing your affection for {childName} more often, such as telling {them} that you love {them}.
          Adapt the way you show affection according to {childName}’s age and maturity.
        </li>
        <li key="2_white_2_a" aria-label="2_white_2_a">
          Making time each day to ask {childName} about {their} day and what {they} {have} been doing, regardless of{' '}
          {their} response.
        </li>
        <li key="2_white_3_a" aria-label="2_white_3_a">
          Trying to start conversations with {childName} when {they} {appear} most open to conversation. While it may be
          hard to tell when {they} {are} open to talking, try to chat when {they} {seem} most responsive to you. For
          example, this could be in the car, at the shops, or before bed.
        </li>
        <li key="2_white_4_b" aria-label="2_white_4_b">
          Showing respect for {childName}’s opinions, even if you don’t agree with {them}.
        </li>
        <li key="2_white_5_a" aria-label="2_white_5_a">
          Letting {childName} know that you are there for {them} whenever {they} {need} it and that {they} can talk to
          you about anything, even difficult issues.
        </li>
        <li key="2_white_6_b" aria-label="2_white_6_b">
          Choosing somewhere private to talk with {childName} about sensitive issues. Having other people around may
          make it difficult for {them} to be honest with you.
        </li>
        <li key="2_white_7_a" aria-label="2_white_7_a">
          Showing your concern when {childName} is feeling a strong emotion by asking about {their} feelings (e.g. “You
          look worried, is there something on your mind?”). Encourage {them} to talk with you about {their} emotions,
          and take the time to listen.
        </li>
        <li key="2_white_8_b" aria-label="2_white_8_b">
          Not telling {childName} to toughen up when {they} {are} upset. Responding in this way can lead {childName} to
          believe that {their} emotions are wrong and {they} {are} bad for having them. Instead, let {them} know that
          you are concerned and offer to chat with {them} about what’s going on.
        </li>
      </ul>

      <p key="2_end" aria-label="2_end">
        For more strategies to help you build a strong relationship with {childName}, check out the{' '}
        <strong>
          <em>Connect</em>
        </strong>{' '}
        module.
      </p>
    </AccordionDetails>,
  )
    .where('intro', valueInRange(sum(scores), [0, 4], [4, 7], 7, [8, Number.MAX_VALUE]))
    .where('white_list', hasValue(scores, 0))
    .element();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pradas-1" id="pradas-1">
        <Typography key="2_heading" color="primary" gutterBottom>
          Your Relationship with {childName}
        </Typography>
      </AccordionSummary>
      {details}
    </Accordion>
  );
};
