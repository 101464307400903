import { Typography } from '@mui/material';

export const Confidence = () => {
  return (
    <section>
      <Typography variant="h5" color="primary" gutterBottom>
        Your confidence and family wellbeing
      </Typography>
      <Typography variant="h6" gutterBottom>
        Next steps
      </Typography>
      <Typography paragraph>
        Thank you for answering these survey questions. Your responses will help us to evaluate the PiP-Ed program.
      </Typography>
      <Typography paragraph>
        If you are concerned about your own or your teen’s mental health, and feel you need more support, we encourage
        you to seek professional help. Please see our Useful Resources page for more information and resources about
        mental health, including where you can access professional support.
      </Typography>
      Now that you’ve finished the survey, you can select your modules and start your program. You will have access to
      your first module{' '}
      <strong>
        <em>Understanding anxiety and depression</em>
      </strong>{' '}
      straight away.
    </section>
  );
};
