import { Box, Typography } from '@mui/material';
import { Link } from 'components';
import { FeedbackComponentProps } from '.';
import { Pradas1 } from './Pradas1';
import { Pradas2 } from './Pradas2';
import { Pradas3 } from './Pradas3';
import { Pradas4 } from './Pradas4';
import { Pradas5 } from './Pradas5';
import { Pradas6 } from './Pradas6';
import { Pradas7 } from './Pradas7';
import { Pradas8 } from './Pradas8';
import { Pradas9 } from './Pradas9';

export function Pradas(props: FeedbackComponentProps) {
  const { vocab } = props;
  const { childName } = vocab;
  return (
    <section>
      <Typography variant="h5" color="primary" gutterBottom>
        Your Personalised Parenting Tips
      </Typography>

      <Typography paragraph>
        We can now provide you with feedback about your role in reducing {childName}’s risk of developing depression and
        clinical anxiety. We also provide you with some practical strategies that you could use to further support{' '}
        {childName}.
      </Typography>

      <Typography variant="h6" gutterBottom>
        What next?
      </Typography>

      <Typography paragraph>
        This feedback message contains a number of strategies that may be useful for you and {childName}. This may seem
        like a lot of information to take in at once. If you’re not sure where to start, you may like to have a quick
        read over it and pick one or two sections to focus on first. You may prefer to start with the changes that you
        feel would make the most difference to you and {childName}, or those that would be easiest to implement. You
        don’t need to try all of these strategies at once. Remember, change can take time and patience. You might
        already feel that you do some of the things recommended to you. If this is the case, you could consider doing
        them more often. If you feel that there are things you could have done differently as a parent, try not to be
        too hard on yourself. You’ve already taken a positive step by completing the survey, well done!
      </Typography>

      <Box sx={{ my: 2 }}>
        <Pradas1 {...props} />
        <Pradas2 {...props} />
        <Pradas3 {...props} />
        <Pradas4 {...props} />
        <Pradas5 {...props} />
        <Pradas6 {...props} />
        <Pradas7 {...props} />
        <Pradas8 {...props} />
        <Pradas9 {...props} />
      </Box>

      <Typography variant="h5" color="primary" gutterBottom>
        Don’t Blame Yourself
      </Typography>

      <Typography paragraph>
        If you feel that there are things you could have done differently as a parent, it’s important not to be too hard
        on yourself. If, despite your best efforts, {childName} does develop depression or anxiety, you should not view
        it as a failure on your part. Any teenager can develop these problems, even in happy, well-adjusted families.
        Remember that it is important to take care of yourself, and seek professional help if you think you need it.
      </Typography>

      <Typography paragraph>
        You can find more information <Link href="/resources">here</Link>.
      </Typography>
    </section>
  );
}
