import { Fragment, ReactNode } from 'react';
import sum from 'lodash/sum';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { calcOptionsScores, FreqScales } from './helpers';
import { select, hasValue, Predicate } from './select';
import type { FeedbackComponentProps } from '.';
import { ExpandMore } from '@mui/icons-material';

export const evaluatePradas7 = calcOptionsScores([
  ['S7#Q1', FreqScales[3]],
  ['S7#Q2', [FreqScales[2], FreqScales[3]]],
  ['S7#Q3', [FreqScales[2], FreqScales[3]]],
  ['S7#Q4', [FreqScales[0], FreqScales[1]]],
  ['S7#Q5', [FreqScales[2], FreqScales[3]]],
  ['S7#Q6', FreqScales[3]],
  ['S7#Q7', [FreqScales[0], FreqScales[1]]],
  ['S7#Q8', [FreqScales[2], FreqScales[3]]],
  ['S7#Q9', FreqScales[0]],
  ['S7#Q10', FreqScales[3]],
]);

export const Pradas7 = ({ vocab, userSurvey: state }: FeedbackComponentProps) => {
  const { childName, them, their, they, themselves, trust, have, are } = vocab;
  const scores = evaluatePradas7(state);
  const lifeScore = scores.slice(0, 4);
  const problemScore = scores.slice(5, 10);
  const sumScore = sum(scores);
  const selectIntro =
    (...range: Array<[number, number]>) =>
    (elm: ReactNode, i: number) => {
      if (i < 3) {
        const [min, max] = range[i];
        return sumScore >= min && sumScore < max;
      }
      return i === 3 ? sumScore === 9 && lifeScore.indexOf(0) >= 0 : false;
    };

  const matchConditions = (): Predicate => {
    const applies: Record<string, boolean> = {
      '8_problems_start': problemScore.findIndex((score) => score === 0) >= 0,
      '8_5_5_a': scores[4] === 0,
    };
    return (elm) => {
      const apply = elm.key ? applies[elm.key] : undefined;
      return apply === undefined ? true : apply;
    };
  };

  const details = select(
    <AccordionDetails>
      <p key="8_body" aria-label="8_body">
        Learning to deal well with problems can help protect {childName} from developing depression and clinical
        anxiety.
      </p>

      <Fragment key="intro">
        <p key="8_score_low" aria-label="8_score_low">
          You can help {childName} to do this by demonstrating effective problem‐solving skills yourself, and teaching{' '}
          {them} how to manage problems and stress in {their} own life. Here are some useful strategies:
        </p>
        <div key="8_score_medium" aria-label="8_score_medium">
          <p>
            You can help {childName} to do this by demonstrating effective problem‐solving skills yourself, and teaching{' '}
            {them} how to manage problems and stress in {their} own life.
          </p>
          <p> You are doing some of this already. Here are some other things you could do:</p>
        </div>
        <div key="8_score_high" aria-label="8_score_high">
          You are doing a great job of helping {childName} to deal with problems in {their} life, keep it up!
        </div>
        <div key="8_score_extra_high" aria-label="8_score_extra_high">
          To help {childName} further develop {their} problem solving skills, keep this in mind:
        </div>
      </Fragment>

      <ul key="life_list">
        <li key="8_life_1_a" aria-label="8_life_1_a">
          Encourage {childName} to set realistic goals. You can help {them} to think of different ways {they} can
          achieve {their} goals, and select the best one.
        </li>

        <li key="8_life_2_a" aria-label="8_life_2_a">
          Pay attention to {childName}’s behaviour and look for signs of stress. If you notice that {they} {are}{' '}
          stressed or upset, help {them} to learn ways to cope with these emotions (e.g. by talking to someone {they}{' '}
          {trust}).
        </li>

        <li key="8_life_3_a" aria-label="8_life_3_a">
          When talking with {childName} about problems that {they} {have} dealt with, recognise and praise {their}{' '}
          problem‐solving efforts (i.e. what {they} did well when trying to solve the problem) rather than focussing on
          the outcome {they} achieved.
        </li>

        <li key="8_life_4_c" aria-label="8_life_4_c">
          Set an example for {childName} by persisting at tasks, even when they are difficult.
        </li>
      </ul>

      <p key="8_problems_start" aria-label="8_problems_start">
        When {childName} faces problems in {their} life:
      </p>

      <ul key="problem_list">
        <li key="8_problems_6_a" aria-label="8_problems_6_a">
          Give {them} time to talk through the problem before offering to discuss solutions.
        </li>
        <li key="8_problems_7_c" aria-label="8_problems_7_c">
          Support {childName} to develop the skills to solve the problem {themselves}, rather than trying to solve the
          problem for {them}.
        </li>
        <li key="8_problems_8_a" aria-label="8_problems_8_a">
          Help {them} to break the problem down into smaller, more manageable steps.
        </li>
        <li key="8_problems_9_b" aria-label="8_problems_9_b">
          Don’t brush off or dismiss {childName}’s problems or concerns. It is important for you to help {childName}{' '}
          learn helpful ways to deal with problems in {their} life. Offer to talk the problem through with {them}, and
          help {them} to problem‐solve the issue.
        </li>
        <li key="8_problems_10_a" aria-label="8_problems_10_a">
          Encourage {them} to consider the effects of {their} actions on other people.
        </li>
      </ul>

      <p key="8_5_5_a" aria-label="8_5_5_a">
        Teenagers often feel pressure to live up to expectations from a range of sources (e.g. themselves, their
        friends, school, the media, or family members). It is important for you to be aware of these pressures so that
        you can help {childName} deal with them. Have a conversation with {childName} about the pressures on {them}{' '}
        (e.g. pressure to do well at school, or pressure from the media to look a certain way) and how these may be
        affecting {them}. Try not to put too much pressure on {childName} to achieve or perform. Excessive parental
        pressure can increase {their} risk of depression and clinical anxiety.
      </p>

      <p key="8_end" aria-label="8_end">
        For more strategies to help support {childName}’s problem‐solving skills, check out the{' '}
        <strong>
          <em>Partners in problem solving</em>
        </strong>{' '}
        module.
      </p>
    </AccordionDetails>,
  )
    .where(matchConditions())
    .where('intro', selectIntro([0, 5], [5, 9], [9, Number.MAX_VALUE]))
    .where('life_list', hasValue<number>(lifeScore, 0))
    .where('problem_list', hasValue<number>(problemScore, 0))
    .element();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pradas-1" id="pradas-1">
        <Typography key="2_heading" color="primary" gutterBottom>
          Dealing with Problems in {childName}’s Life
        </Typography>
      </AccordionSummary>
      {details}
    </Accordion>
  );
};
