import sum from 'lodash/sum';
import { Typography, AccordionDetails, Accordion, AccordionSummary } from '@mui/material';
import { calcOptionsScores, FreqScales } from './helpers';
import { select, hasValue, valueInRange } from './select';
import type { FeedbackComponentProps } from '.';
import { ExpandMore } from '@mui/icons-material';

export const evaluatePradas3 = calcOptionsScores([
  ['S3#Q1', [FreqScales[2], FreqScales[3]]],
  ['S3#Q2', [FreqScales[2], FreqScales[3]]],
  ['S3#Q3', [FreqScales[2], FreqScales[3]]],
  ['S3#Q4', [FreqScales[0], FreqScales[1]]],
  ['S3#Q5', [FreqScales[2], FreqScales[3]]],
  ['S3#Q6', [FreqScales[0], FreqScales[1]]],
]);

export const Pradas3 = ({ vocab, userSurvey: state }: FeedbackComponentProps) => {
  const { childName, their, them } = vocab;
  const scores = evaluatePradas3(state);
  const details = select(
    <AccordionDetails>
      <p key="4_body" aria-label="4_body">
        Having good social skills and supportive relationships with a range of people of different ages helps to reduce{' '}
        {childName}’s risk of depression and clinical anxiety.
      </p>

      <div key="intro">
        <p key="4_white_low" aria-label="4_white_low">
          To help {childName} in this area, you could try the following strategies:
        </p>
        <p key="4_white_medium" aria-label="4_white_medium">
          You already help {childName} in this area in several ways. To help build {their} social skills further, you
          could:
        </p>
        <div key="4_white_high" aria-label="4_white_high">
          <p>
            It’s great that you help {childName} to develop {their} social skills and have positive relationships with
            others in lots of ways.
          </p>
          <p>To further improve {their} social skills, you could also:</p>
        </div>
        <p key="4_white_best" aria-label="4_white_best">
          It’s great that you help {childName} to develop {their} social skills and have positive relationships with
          others in lots of ways.
        </p>
      </div>

      <ul key="white_list">
        <li key="4_white_1_a" aria-label="4_white_1_a">
          Encourage {childName} to spend time with {their} friends more often.
        </li>
        <li key="4_white_2_a" aria-label="4_white_2_a">
          Encourage {childName} to do kind things for others.
        </li>
        <li key="4_white_3_a" aria-label="4_white_3_a">
          Encourage {childName} to participate in a range of social situations.
        </li>
        <li key="4_white_4_c" aria-label="4_white_4_c">
          Encourage {childName} to spend time with people of various ages (both young and old), to help {them} develop a
          range of social skills and supportive relationships. These could include friends, relatives (e.g. younger and
          older cousins), neighbours, or other important people in {their} life.
        </li>
        <li key="4_white_5_a" aria-label="4_white_5_a">
          Take some time to talk through any social problems {childName} may have.
        </li>
        <li key="4_white_6_c" aria-label="4_white_6_c">
          Encourage {childName} to spend time with both friends and family on weekends.
        </li>
      </ul>

      <p key="4_end" aria-label="4_end">
        If you’d like to learn more about helping {childName} build supportive relationships, check out the module{' '}
        <strong>
          <em>Good friends = supportive relationships</em>
        </strong>
        .
      </p>
    </AccordionDetails>,
  )
    .where('intro', valueInRange(sum(scores), [0, 3], [3, 5], 5, [6, Number.MAX_VALUE]))
    .where('white_list', hasValue(scores, 0))
    .element();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pradas-1" id="pradas-1">
        <Typography key="2_heading" color="primary" gutterBottom>
          {childName}’s Relationships with Others
        </Typography>
      </AccordionSummary>
      {details}
    </Accordion>
  );
};
