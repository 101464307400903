import { Fragment, useMemo } from 'react';
import { Divider, Typography } from '@mui/material';
import { calcScaleScore, SimpleFreqScales } from './helpers';
import { select } from './select';

import type { FeedbackComponentProps } from '.';
import type { state, Gender } from 'app';

type Config = {
  range: number[];
  genderFactors: {
    female: Array<[number, number]>;
    other: Array<[number, number]>;
  };
};

const evaluateScore = ({ range, genderFactors }: Config) => {
  const scaleScore = calcScaleScore(
    range.map((i) => `s1#q${i}`),
    {
      [SimpleFreqScales[0]]: 0,
      [SimpleFreqScales[1]]: 1,
      [SimpleFreqScales[2]]: 2,
      [SimpleFreqScales[3]]: 3,
    },
  );

  return (userSurvey: state.Survey, gender: Gender, grade: number) => {
    const rawScore = scaleScore(userSurvey);
    const factors = gender === 'female' ? genderFactors.female : genderFactors.other;
    const [factor1, factor2] = factors[Math.floor((grade - 5) / 2)] || factors[0];
    return 50 + ((rawScore - factor1) * 10) / factor2;
  };
};

export const evaluateAnxiety = evaluateScore({
  range: [2, 3, 5, 6, 7, 9, 11, 12, 14, 17, 18, 20, 22, 23, 25],
  genderFactors: {
    female: [
      [7.29, 5.25],
      [5.8, 3.91],
      [5.94, 5.27],
      [5.76, 3.97],
    ],
    other: [
      [6.1, 4.15],
      [5.27, 3.95],
      [6.23, 4.56],
      [4.66, 3.58],
    ],
  },
});

export const evaluateDepression = evaluateScore({
  range: [1, 4, 8, 10, 13, 15, 16, 19, 21, 24],
  genderFactors: {
    female: [
      [3.75, 3.63],
      [3.6, 3.37],
      [3.97, 3.25],
      [4.91, 3.17],
    ],
    other: [
      [3.62, 2.87],
      [3.54, 3.18],
      [5.21, 3.51],
      [3.94, 3.88],
    ],
  },
});

export const Rcads = ({ vocab, user, userSurvey }: FeedbackComponentProps) => {
  const element = useMemo(() => {
    const { childGender, childGrade } = user!;
    const { childName, them, they, are } = vocab;
    const anxiety = evaluateAnxiety(userSurvey, childGender, childGrade) >= 70 ? 1 : 0;
    const depression = evaluateDepression(userSurvey, childGender, childGrade) >= 70 ? 1 : 0;
    const bodyIndex = anxiety * 2 + depression * 1;

    return select(
      <section>
        <Typography key="intro" variant="h5">
          {childName}’s mental wellbeing
        </Typography>
        <Divider />

        <Fragment key="body">
          <p key="body_no_anxiety_no_depression" aria-label="body_no_anxiety_no_depression">
            Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses,{' '}
            {childName} does <strong>not</strong> seem to be experiencing significant symptoms of anxiety or depression.
            Keep in mind that these questions only give us a snapshot of {childName}’s mental health, and are not a
            replacement for professional advice. You know your child best – if you are ever worried about {childName}’s
            mental health, we suggest you check in with {them} about how {they} {are} feeling. You can also speak with
            your GP or health professional.
          </p>
          <p key="body_no_anxiety_but_depression" aria-label="body_no_anxiety_but_depression">
            Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses,{' '}
            {childName} could be experiencing symptoms of depression. Keep in mind that these questions only give us a
            snapshot of {childName}’s mental health, and are not a replacement for professional advice. We suggest you
            check in with {childName} about how {they} {are} feeling and also consider seeking professional support.
            We’ve sent you an email with more information – keep an eye out for this in your inbox.
          </p>

          <p key="body_anxiety_no_depression" aria-label="body_anxiety_no_depression">
            Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses,{' '}
            {childName} could be experiencing symptoms of anxiety. Keep in mind that these questions only give us a
            snapshot of {childName}’s mental health, and are not a replacement for professional advice. We suggest you
            check in with {childName} about how {they} {are} feeling and also consider seeking professional support.
            We’ve sent you an email with more information – keep an eye out for this in your inbox.
          </p>

          <p key="body_anxiety_depression" aria-label="body_anxiety_depression">
            Thanks for answering the questions about {childName}’s thoughts and feelings. Based on your responses,{' '}
            {childName} could be experiencing symptoms of anxiety and depression. Keep in mind that these questions only
            give us a snapshot of {childName}’s mental health, and are not a replacement for professional advice. We
            suggest you check in with {childName} about how {they} {are} feeling and also consider seeking professional
            support. We’ve sent you an email with more information – keep an eye out for this in your inbox.
          </p>
        </Fragment>
      </section>,
    )
      .where('body', (elm, i) => i === bodyIndex)
      .element();
  }, [user, userSurvey, vocab]);

  return element;
};
