import { useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'components/Link';
import { headings } from 'app';
import { AppPage } from 'app/layout';
import { useAppSelector, useAppDispatch, startSurvey } from 'app/store';
import { ensureAuth } from 'app/helpers';
import { SurveyQuestions } from 'app/survey/SurveyQuestions';
import { SurveyFeedback } from 'app/survey/SurveyFeedback';
import { contextualSurvey } from 'app/survey/helpers';
import { updateSurvey } from 'app/store';

import type { state, data, UserSurvey } from 'app';

type SurveyTemplateProps = {
  pageContext: PageProps['pageContext'];
  allUserSurveys: Record<string, state.Survey>;
  user: state.User;
  userSurvey: state.Survey;
  survey: data.Survey;
};

export function SurveyTemplate(props: SurveyTemplateProps) {
  const { user, allUserSurveys, userSurvey, survey, pageContext } = props;
  const { nextId, prevId } = pageContext;
  const activeStep = userSurvey._step || 0;
  const inprogress = activeStep < survey.sections.data.length;
  const dispatch = useAppDispatch();
  const normalizedSurvey = useMemo(() => contextualSurvey(survey, user), [user, survey]);

  // unlock next
  useEffect(() => {
    if (nextId && !inprogress) {
      const next = allUserSurveys[nextId];
      if (!next) {
        dispatch(startSurvey({ surveyId: nextId }));
      }
    }
  }, [nextId, inprogress]);

  const handleUpdateSurvey = (data: Partial<UserSurvey>) => {
    dispatch(updateSurvey({ id: survey.key, data }));
  };

  return (
    <AppPage title="Survey">
      <Box my={2}>
        <Breadcrumbs aria-label="breadcrumb-feedback">
          <Link color="inherit" href="/">
            Home
          </Link>
          {survey.scope === 'baseline' ? (
            <Link color="inherit" href="/dashboard#baseline">
              Dashboard
            </Link>
          ) : (
            <Link color="inherit" href="/dashboard/#followup">
              Dashboard
            </Link>
          )}
          <Typography color="textSecondary">
            {survey.scope === 'baseline' ? headings.survey : headings.followup}
          </Typography>
          <Typography color="textPrimary">{normalizedSurvey.title}</Typography>
        </Breadcrumbs>
      </Box>

      {inprogress ? (
        <SurveyQuestions activeStep={activeStep} survey={normalizedSurvey} userSurvey={userSurvey} isLast={!nextId} />
      ) : (
        <SurveyFeedback
          user={user}
          survey={normalizedSurvey}
          userSurvey={userSurvey}
          nextId={nextId}
          prevId={prevId}
          updateUserSurvey={handleUpdateSurvey}
        />
      )}
    </AppPage>
  );
}

type PageProps = {
  data: {
    survey: data.Survey;
  };
  pageContext: {
    nextId: string;
    prevId: string;
    followupIds: string[];
    baselineIds: string[];
  };
};

export default ensureAuth((props: PageProps) => {
  const {
    data: { survey },
    pageContext,
  } = props;

  const { user, surveys: userSurveys } = useAppSelector((state) => state.profile.data!);
  const userSurvey = userSurveys[survey.key];
  if (!userSurvey) {
    return <div>The survey is locked</div>;
  }

  return (
    <SurveyTemplate
      pageContext={pageContext}
      user={user}
      survey={survey}
      allUserSurveys={userSurveys}
      userSurvey={userSurvey}
    />
  );
});

export const query = graphql`
  query ($key: String) {
    survey: strapiSurvey(key: { eq: $key }) {
      ...SurveyFragment
    }
  }

  fragment SurveyFragment on STRAPI_SURVEY {
    key
    title
    tint
    extraStyle {
      left
      bottom
      height
    }
    scope
    cover {
      localFile {
        publicURL
      }
    }
    sections {
      data: strapi_json_value {
        key
        title
        type
        scales
        options
        required
        questions {
          key
          label
          title
          type
          cond
          scales
          size
          options
          answers
          variant
          content
          required
        }
      }
    }
  }
`;
